<template>
  <div class="buy">
    <div class="buy_box">
      <div :class="index_active == 1 ? 'active' : ''">
        <router-link to="/buy/buy_fiat" replace>
          {{ $t("main.text51") }}
        </router-link>
      </div>
      <div :class="index_active == 2 ? 'active' : ''">
        <router-link to="/buy/buy_jmhb" replace>USDT-TRC20</router-link>
      </div>
    </div>
    <div class="view_box">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index_active: 1,
    };
  },
  beforeMount() {
    sessionStorage.setItem("hichat_title", this.$t("main.text48"));
    sessionStorage.setItem("hichat_icon_show", true);
    sessionStorage.setItem("hichat_return_home", true);
  },
  beforeDestroy() {
    sessionStorage.setItem("hichat_icon_show", "");
    sessionStorage.setItem("hichat_return_home", "");
  },
  mounted() {
    this.set_active();
  },
  watch: {
    $route() {
      this.set_active();
    },
  },
  methods: {
    set_active() {
      if (this.$route.path == "/buy/buy_fiat") {
        this.index_active = 1;
      } else if (this.$route.path == "/buy/buy_jmhb") {
        this.index_active = 2;
      }
    },
  },
};
</script>

<style scoped>
.buy {
  border-top: 1px solid #f5f6f7;
}
.buy_box {
  display: flex;
  background: #fff;
  box-sizing: border-box;
  padding: 0.65rem 0;
}
.buy_box > div {
  width: 50%;
  text-align: center;
}
.buy_box a {
  color: rgba(173, 173, 173, 1);
  box-sizing: border-box;
  padding-bottom: 0.65rem;
}
.active {
  color: #2982d8;
}
.active a {
  color: #2982d8;
  border-bottom: 1.5px solid #2982d8;
}
.view_box {
  box-sizing: border-box;
  padding: 1rem;
}
</style>
